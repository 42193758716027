import React from 'react';
import styled from 'styled-components';
import Grid from '@ntuctech/devex-tangram/Grid';
import Header from '../../../containers/Header';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .no-spacing-layout {
    padding: 0;
    margin: 0;
  }
`;

export const LayoutContent = ({ children }) => {
  return (
    <Grid container>
      <Grid item data-testid="LayoutContent">
        {children}
      </Grid>
    </Grid>
  );
};

export const LayoutContentFullScreen = ({ children }) => {
  return (
    <Grid container compact>
      <Grid item className="webR-grid-item-compact" data-testid="LayoutContentFullScreen">
        {children}
      </Grid>
    </Grid>
  );
};

function Layout({ children, className }) {
  return (
    <>
      <Container className={className} data-testid="layout">
        <Header />
        {children}
      </Container>
    </>
  );
}

export default Layout;
