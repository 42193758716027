/* eslint-disable react/jsx-handler-names */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonText, Typography, Grid } from '@ntuctech/devex-tangram';
import nookies from 'nookies';
import { useAuth0 } from '@auth0/auth0-react';
import {
  MOBILE_NAVIGATION_B2B,
  MOBILE_NAVIGATION_B2C,
  MOBILE_NAVIGATION_B2B_NO_ENTITY,
  NON_LOGIN_B2B,
} from '../../../../lib/navigation';
import { SCREEN_SIZE } from '../../../../lib/mediaQuery';
import MobileNavCloseIcon from '../../../icons/MobileNavClose';
import { StyledPopup } from '../../../Popup/StyledPopup';
import { disablePageScroll } from '../../../../lib/utils';
import { B2B, B2C, REDIRECT_URL } from '../../../../constants';
import useAuthenticationState from '../../../../hooks/useAuthenticationState';
import { CustomiseVoucherContext } from '../../../Context/Context';
import useBusinessEntityData from '../../../../hooks/useBusinessEntityData';

const StyledNavContainer = styled.nav`
  .sub-menu {
    margin-left: 1.5rem;
  }
  width: 80%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  z-index: 10057;
  top: 0;
  .no-spacing {
    padding: 0;
    margin: 0;
  }
  left: ${(props) => (props.show ? '0' : '-80%')};
  transition: left 0.5s ease;
  ${SCREEN_SIZE.Only.Desktop} {
    display: none;
  }
  ${SCREEN_SIZE.From.Tablet} {
    width: 45%;
    left: ${(props) => (props.show ? '0' : '-45%')};
  }
`;

const StyledNavContent = styled.div`
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  box-sizing: border-box;
`;

const StyledNavClose = styled(MobileNavCloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

const NewLabelBox = styled.div`
  padding: 0.0625rem 0.125rem;
  margin-left: 0.25rem;
  background-color: #ff8833;
  border-radius: 0.125rem;
  display: inline-block;
  line-height: 0;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 !important;
  margin: 0 !important;
  min-width: unset !important;
  display: block;
  text-align: left;
  height: 1.5rem;

  &:hover,
  &:active,
  &:focus {
    background-color: unset !important;
    box-shadow: none;
    span {
      color: #1454b8;
    }
  }
  &:active:before {
    box-shadow: none;
  }

  ${({ isActive }) =>
    isActive &&
    `
    color: #1557bf;
    border-left: 4px solid #1557bf;
    margin-left: -16px;
    padding-left: 12px;

    svg {
      color: #1557bf;
    }
  `}

  span {
    ${({ active }) => active && 'color: #1454b8; font-weight: 700; margin: 0; padding: 0;'};
  }

  ${NewLabelBox} {
    transform: translateY(-0.2rem);
  }

  ${({ hasAlert }) =>
    hasAlert &&
    css`
      :after {
        content: '';
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;
        background-color: #c10b3a;
        border-radius: 50%;
        transform: translateY(-0.6rem);
      }
    `}
`;

const StyledUl = styled.ul`
  margin-top: 0;
  height: 100%;
  li {
    margin: 0.75rem 0;
  }
`;

const StyledNavDivider = styled.div`
  height: 0;
  color: #eaeaea;
  padding: 0;
  border: 0.063rem solid #eaeaea;
`;

const StyledNavItem = styled.li`
  cursor: pointer;
`;

function RenderLoginLogoutLink({ activeIndex, setActiveIndex }) {
  const { isConfirmedLoggedIn } = useAuthenticationState();
  const { logout, loginWithRedirect } = useAuth0();
  const { destroyeeAllNookies } = useContext(CustomiseVoucherContext);

  return (
    <StyledNavItem>
      {!isConfirmedLoggedIn ? (
        <StyledButtonText
          size="s"
          active={activeIndex === 5}
          onClick={() => {
            nookies.set({}, REDIRECT_URL, window.location.href);
            loginWithRedirect({ redirectUri: window.location.origin });
            setActiveIndex(5);
          }}
          data-testid="loginButton"
        >
          <Typography variant="bodyM">Log in/Sign up</Typography>
        </StyledButtonText>
      ) : (
        <StyledButtonText
          size="s"
          active={activeIndex === 6}
          onClick={() => {
            destroyeeAllNookies();
            logout({ returnTo: window.location.origin });
            setActiveIndex(6);
          }}
          data-testid="logoutButton"
        >
          <Typography variant="bodyM">Logout</Typography>
        </StyledButtonText>
      )}
    </StyledNavItem>
  );
}

function MobileNavigationList({
  isMyordersEnable,
  hasAlert,
  handleNavigationClick,
  router,
  activeNavPath,
  activeNavLabel,
  activeIndex,
  setActiveIndex,
}) {
  const { isConfirmedLoggedIn } = useAuthenticationState();
  const { isUserAccHasBusinessEntity, isB2BSite } = useBusinessEntityData();

  const onHomeScreenHandler = useCallback(
    (index) => {
      setActiveIndex(index);
      window.location.href = `/?type=${isB2BSite ? B2B : B2C}`;
    },
    [isB2BSite, setActiveIndex]
  );

  const getNavList = useCallback(() => {
    if (isConfirmedLoggedIn && isB2BSite) {
      return isUserAccHasBusinessEntity ? MOBILE_NAVIGATION_B2B : MOBILE_NAVIGATION_B2B_NO_ENTITY;
    }
    if (!isConfirmedLoggedIn && isB2BSite) {
      return NON_LOGIN_B2B;
    }
    return MOBILE_NAVIGATION_B2C;
  }, [isB2BSite, isConfirmedLoggedIn, isUserAccHasBusinessEntity]);

  return getNavList()?.map((link, index) => {
    const { label, url, subMenu, isNewTab, subMenuLink } = link;
    const labelToDisplay = label;
    let isAccountChild = false;

    isAccountChild = window.location.hash?.toUpperCase().includes(label?.toUpperCase());

    const isActive =
      isAccountChild ||
      (activeNavPath && activeNavPath === url?.split('/')[1] && window.location.hash === '') ||
      (activeNavLabel && activeNavLabel === labelToDisplay);

    const active = index === activeIndex;
    const isMyOrderActive = `/${activeNavPath}/` === '/my-orders/';

    return (
      <>
        {url || isNewTab ? (
          <li key={labelToDisplay} data-name={labelToDisplay} data-testid="nav-item">
            <StyledButtonText
              size="s"
              hasAlert={hasAlert}
              isActive={isActive}
              data-testid={label}
              active={active}
              passHref
              onClick={() => {
                setActiveIndex(index);
                // eslint-disable-next-line no-unused-expressions
                isNewTab ? onHomeScreenHandler(index) : window.open(url, '_blank');
              }}
            >
              <Typography variant="bodyM">{labelToDisplay}</Typography>
            </StyledButtonText>
          </li>
        ) : (
          <li key={labelToDisplay} data-name={labelToDisplay} data-testid="nav-item">
            <StyledButtonText
              size="s"
              onClick={() => {
                setActiveIndex(index);
                /* eslint-disable no-unused-expressions */
                !subMenu ? handleNavigationClick(index) : onHomeScreenHandler(index);
              }}
              active={active && !isMyOrderActive}
              hasAlert={hasAlert}
              isActive={isActive}
              data-testid={label}
            >
              <Typography variant={active ? 'bodyMBold' : 'bodyM'}>{labelToDisplay}</Typography>
            </StyledButtonText>
          </li>
        )}
        {index === 0 && <StyledNavDivider />}

        {subMenu && isMyordersEnable && (
          <li
            key={labelToDisplay}
            className="sub-menu"
            data-name={labelToDisplay}
            data-testid="nav-item"
          >
            <Grid className="no-spacing">
              <StyledButtonText
                size="s"
                data-testid={subMenu}
                active={isMyOrderActive}
                onClick={() => {
                  setActiveIndex(subMenu);
                  router?.push(subMenuLink);
                }}
              >
                <Typography variant={activeIndex === subMenu ? 'bodyMBold' : 'bodyM'}>
                  {subMenu}
                </Typography>
              </StyledButtonText>
            </Grid>
          </li>
        )}
      </>
    );
  });
}

const MobileNavigationInner = ({
  navShow,
  handleClose,
  isMyordersEnable,
  hasAlert,
  router,
  handleNavigationClick,
  deliveryModeB2B,
}) => {
  const [activeNavPath, setActiveNavPath] = useState(undefined);
  const [activeNavLabel, setActiveNavLabel] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTouchEnd = (e) => {
    if (e && e.target.dataset?.testid?.toString().includes('mobileNavOverlay')) {
      e.stopPropagation();
      disablePageScroll(false);
      handleClose();
    }
  };

  useEffect(() => {
    disablePageScroll(navShow);
    window.addEventListener('click', handleTouchEnd, false);
    setActiveIndex(deliveryModeB2B ? 1 : 0);

    if (router) {
      const currentPath = router?.pathname?.split('/')[1];
      const secondaryMatchers = {
        categoryDetails: 'Categories',
        categories: 'Categories',
        category: 'Categories',
        payment: 'Payment',
      };
      setActiveNavPath(currentPath);
      setActiveNavLabel(secondaryMatchers[currentPath]);
    }

    return () => {
      window.removeEventListener('click', handleTouchEnd, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryModeB2B]);

  return (
    <>
      {navShow && disablePageScroll(true)}
      {navShow && <StyledPopup data-testid="mobileNavOverlay" />}
      <StyledNavContainer show={navShow}>
        <StyledNavContent>
          <StyledNavClose data-testid="close-mobilenav" onClick={handleClose} />
          <StyledUl>
            <MobileNavigationList
              isMyordersEnable={isMyordersEnable}
              hasAlert={hasAlert}
              handleNavigationClick={handleNavigationClick}
              router={router}
              activeNavPath={activeNavPath}
              activeNavLabel={activeNavLabel}
              activeIndex={activeIndex}
              setActiveIndex={(idx) => {
                setActiveIndex(idx);
              }}
            />
            <>
              <StyledNavDivider isLogout />
              <RenderLoginLogoutLink
                activeIndex={activeIndex}
                setActiveIndex={(idx) => {
                  setActiveIndex(idx);
                }}
              />
            </>
          </StyledUl>
        </StyledNavContent>
      </StyledNavContainer>
    </>
  );
};

const MobileNavigation = (props) => {
  return <MobileNavigationInner {...props} />;
};

export default MobileNavigation;
