import React from 'react';
import { Typography, SvgArrowDown, SvgArrowDownFilled } from '@ntuctech/devex-tangram';
import Chip from '@ntuctech/devex-tangram/Chip';
import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../lib/mediaQuery';

const themeColor = 'var(--mine-shaft-700, var(--colour-mineshaft-700, #333))';

const StyledArrowDown = styled(SvgArrowDown)`
  width: 1rem;
  height: 1rem;
  & path {
    fill: ${(p) => (p.deliveryModeB2B ? '#fff' : `${themeColor}`)} !important;
  }
`;

const StyledArrowDownFilled = styled(SvgArrowDownFilled)`
  width: 1rem;
  height: 1rem;
  & path {
    fill: ${(p) => (p.deliveryModeB2B ? '#fff' : `${themeColor}`)};
  }
`;

const StyledChipHeader = styled(Chip)`
  &.chip-header {
    background-color: ${(p) => (p.deliveryModeB2B ? '#0B2E65' : '#fff')};
    margin: 0 1.5rem !important;
    ${SCREEN_SIZE.Only.Tablet} {
      margin: 0 1.25rem !important;
    }
    &:hover {
      background-color: inherit;
    }
    &:focus {
      background-color: inherit;
    }
    &:active {
      background-color: inherit;
      ::before {
        box-shadow: 0;
      }
    }
    h6 {
      display: flex;
      align-items: flex-end;
    }
    ${SCREEN_SIZE.Below.MobileL} {
      height: 1.25rem;
      border: 0 !important;
      margin: 0 !important;
      padding: 0;
      display: none;
    }
  }
  div {
    order: 1;
    svg {
      margin-left: 0.25rem;
      margin-right: 0;
    }
  }
`;

const StyledTypography = styled(Typography)`
  &.header-label {
    color: ${(props) => (props.deliveryModeB2B ? '#fff' : `${themeColor}`)};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.251rem;
  }
`;

const HeaderBusiness = ({ deliveryModeB2B, handlerOnClick, isMobile }) => {
  return (
    <StyledChipHeader
      variant="filter"
      className="chip-header"
      data-testid="user-option"
      deliveryModeB2B={deliveryModeB2B}
      onClick={handlerOnClick}
      icon={
        isMobile ? (
          <StyledArrowDownFilled size="s" deliveryModeB2B={deliveryModeB2B} />
        ) : (
          <StyledArrowDown size="s" deliveryModeB2B={deliveryModeB2B} />
        )
      }
      label={
        <StyledTypography
          deliveryModeB2B={deliveryModeB2B}
          variant="subhead1"
          className="header-label"
        >
          {deliveryModeB2B ? 'Business' : 'Personal'}
        </StyledTypography>
      }
    />
  );
};

export default HeaderBusiness;
